<template>
	<div class="m_common_content">
		<div class="m_common_top">
			<div class="m_checkdata_content">
				<el-date-picker v-model="selectDate" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']" value-format="yyyy-MM-dd HH:mm:ss" style="max-width: 205px !important; vertical-align: bottom">
				</el-date-picker>

				<el-select v-model="franchiseeId" placeholder="请选择加盟商" clearable @change="franchiseeChange">
					<el-option :label="item.name" :value="item.id" v-for="(item, i) in franchiseeList" :key="i">
					</el-option>
				</el-select>
				<!--<el-cascader
          :options="treeData"
          :show-all-levels="false"
          :props="cascaderProps"
          v-model="areaData"
          placeholder="请选择行政区域"
          clearable
        ></el-cascader>
        <el-select
          v-model="partnerId"
          placeholder="请选择合伙人"
          clearable
          @change="partnerChange"
        >
          <el-option
            :label="item.name"
            :value="item.id"
            v-for="(item, i) in partnerList"
            :key="i"
          >
          </el-option>
        </el-select>
				<el-select v-model="pointId" placeholder="请选择点位" clearable>
          <el-option
            :label="item.name"
            :value="item.id"
            v-for="(item, i) in pointList"
            :key="i"
          >
          </el-option>
        </el-select>-->
				<el-input v-model="searchContex" placeholder="请输入商品名称搜索" clearable></el-input>
				<el-button @click="search" class="m_btn_search">搜索</el-button>
				<el-button @click="exportEvent" class="m_btn_export">导出</el-button>
			</div>
		</div>
		<div class="m_common_middle">
			<div class="m_common_middle_top">
				<div id="m_chart_content"></div>
			</div>
			<div class="m_common_middle_bottom">
				<div class="m_table_content">
					<el-table :data="tableData" stripe>
						<el-table-column label="序号" type="index" width="100" show-overflow-tooltip>
						</el-table-column>
						<el-table-column prop="saleDate" label="日期" show-overflow-tooltip>
						</el-table-column>
						<el-table-column prop="cataName" label="商品分类" show-overflow-tooltip>
						</el-table-column>
						<el-table-column prop="name" label="商品名称" show-overflow-tooltip>
						</el-table-column>
						<el-table-column prop="saleqty" label="单日销售数量" show-overflow-tooltip>
						</el-table-column>
						<el-table-column prop="saleamt" label="单日销售金额" show-overflow-tooltip>
						</el-table-column>
					</el-table>
				</div>
			</div>
		</div>
		<div class="m_common_bottom">
			<m-pagination @pageChange="pageChange" :total="total"></m-pagination>
		</div>
	</div>
</template>
<script>
	import elDragDialog from "@/directive/el-drag-dialog";
	import mPagination from "@/components/pagination/index.vue";
	import { PAGE_SIZE ,XHR_URL} from "@/utils/config";
	import { goodssalesReports, goodssalesExport ,goodssalesSum} from "@/api/reportForm";
	// 筛选相关接口
	import { regions } from "@/api/base";
	import { franchiseesSelect } from "@/api/partner";
	import { operatorsSelect } from "@/api/franchisee";
	import { locationsSelect } from "@/api/areaPoint";
	export default {
		directives: {
			elDragDialog
		},
		data() {
			const _this = this;
			return {
				total: 0,
				tableData: [],
				myChart: null,
				currentPage: 1,
				// 筛选框相关数据
				partnerList: [],
				franchiseeList: [],
				pointList: [],
				treeData: [],
				cascaderProps: {
					lazy: true,
					checkStrictly: true,
					async lazyLoad(node, resolve) {
						const result = await _this.lazyLoad(node);
						resolve(result);
					},
				},
				selectDate: null,
				areaData: null,
				partnerId: null,
				franchiseeId: null,
				pointId: null,
				searchContex: null,
			};
		},
		props: {},
		computed: {
			pageSize() {
				return PAGE_SIZE;
			},
			fileUrl(){
				return XHR_URL;
			}
		},
		components: {
			mPagination,
		},
		async created() {
			this.franchiseesSelect();
			this.operatorsSelect();
			this.locationsSelect();
			const res = await this.regions();
			this.treeData = res;
		},
		mounted() {
			this.search();
		},
		methods: {
			  initChart(results) {
			      let xAxisData = [];
			      let seriesData = [];
			      results.data.forEach((item) => {
			        xAxisData.push(item.index);
			        seriesData.push(item.value);
			      });
			      let option = {
			        xAxis: {
			          name: results.axisX.title,
			          type: "category",
			          data: xAxisData,
			          axisLabel:{
			          	interval:0,
			          	rotate: 15
			          }
			        },
			        yAxis: {
			          name: results.axisY.title,
			          type: "value",
			        },
			        series: [
			          {
			            data: seriesData,
			            type: "bar",
			            showBackground: true,
			            barWidth: 30,
			            backgroundStyle: {
			              color: "rgba(180, 180, 180, 0.2)",
			            },
			            itemStyle: {        //上方显示数值
			                normal: {
			                    label: {
			                        show: true, //开启显示
			                        position: 'top', //在上方显示
			                        textStyle: { //数值样式
			                            color: 'black',
			                            fontSize: 12
			                        }
			                    }
			                }
			            }
			          },
			        ],
			      };
			      let myChart = this.$echarts.init(
			        document.getElementById("m_chart_content")
			      );
			      this.myChart = myChart;
			      myChart.setOption(option);
			      window.addEventListener(
			        "resize",
			        function () {
			          if (myChart) {
			            myChart.resize();
			          }
			        },
			        false
			      );
			},
			pageChange(page) {
				this.currentPage = page;
				this.search();
			},
			search() {
				const queryData = {
					beginDate: this.selectDate && this.selectDate[0] ? this.selectDate[0] : null,
					endDate: this.selectDate && this.selectDate[1] ? this.selectDate[1] : null,
					province: this.areaData && this.areaData[0] ? this.areaData[0] : null,
					city: this.areaData && this.areaData[1] ? this.areaData[1] : null,
					county: this.areaData && this.areaData[2] ? this.areaData[2] : null,
					franchi: this.partnerId && String(this.partnerId).length > 0 ?
						this.partnerId : null,
					operator: this.franchiseeId && String(this.franchiseeId).length > 0 ?
						this.franchiseeId : null,
					location: this.pointId && String(this.pointId).length > 0 ? this.pointId : null,
					name: this.searchContex && String(this.searchContex).length > 0 ?
						this.searchContex : null,
				};
				this.select(queryData);
				this.getChartData(queryData);
			},

			exportEvent() {
				const queryData = {
					beginDate: this.selectDate && this.selectDate[0] ? this.selectDate[0] : null,
					endDate: this.selectDate && this.selectDate[1] ? this.selectDate[1] : null,
					province: this.areaData && this.areaData[0] ? this.areaData[0] : null,
					city: this.areaData && this.areaData[1] ? this.areaData[1] : null,
					county: this.areaData && this.areaData[2] ? this.areaData[2] : null,
					franchi: this.partnerId && String(this.partnerId).length > 0 ?
						this.partnerId : null,
					operator: this.franchiseeId && String(this.franchiseeId).length > 0 ?
						this.franchiseeId : null,
					location: this.pointId && String(this.pointId).length > 0 ? this.pointId : null,
					name: this.searchContex && String(this.searchContex).length > 0 ?
						this.searchContex : null,
				};
				this.exportFile(queryData);
			},
			async exportFile(queryData) {
				queryData = queryData ? queryData : {};
				const res = await goodssalesExport(queryData);
				const {
					pageCount,
					results
				} = res;
				const a = document.createElement("a");
				const e = document.createEvent("MouseEvents");
				e.initEvent("click", false, false);
				a.href = this.fileUrl + results[0].url;
				a.download = results[0].fileName;
				a.dispatchEvent(e);
			},
			async select(queryData) {
				queryData = queryData ? queryData : {};
				queryData.pageNo = this.currentPage;
				queryData.pageSize = this.pageSize;
				const res = await goodssalesReports(queryData);
				const {
					pageCount,
					results
				} = res;
				this.tableData = results;
				this.total = pageCount.allCount;
			},
			async getChartData(queryData) {
		      const res = await goodssalesSum(queryData);
		      const { pageCount, results } = res;
		      this.initChart(results[0]);
		    },
			async lazyLoad(node) {
				const {
					level
				} = node;
				let query = null;
				switch(level) {
					case 1:
						query = {
							province: node.path[0],
						};
						break;
					case 2:
						query = {
							province: node.path[0],
							city: node.path[1],
						};
						break;
				}
				if(!query) return;
				const res = await this.regions(query, level);
				return res;
			},
			partnerChange() {
				this.franchiseeId = null;
				this.operatorsSelect();
			},
			franchiseeChange() {
				this.pointId = null;
				this.locationsSelect();
			},
			async franchiseesSelect() {
				const res = await franchiseesSelect({
					pageNo: 1,
					pageSize: 999999,
				});
				const {
					pageCount,
					results
				} = res;
				this.partnerList = results;
			},
			async operatorsSelect() {
				const res = await operatorsSelect({
					pageNo: 1,
					pageSize: 999999,
					franchi: this.partnerId,
				});
				const {
					pageCount,
					results
				} = res;
				this.franchiseeList = results;
			},
			async locationsSelect() {
				const res = await locationsSelect({
					pageNo: 1,
					pageSize: 999999,
					id: this.franchiseeId,
				});
				const {
					pageCount,
					results
				} = res;
				this.pointList = results;
			},
			async regions(data, level) {
				const res = await regions(data);
				const {
					pageCount,
					results
				} = res;
				let list = [];
				results.forEach((item) => {
					list.push({
						value: item,
						label: item,
						leaf: level === 2,
					});
				});
				return list;
			},
		},
		beforeDestroy() {},
		destroyed() {},
	};
</script>
<style lang="less" scoped>
	@import "../../assets/css/common/common.less";
</style>
<style lang="less">
	@import "../../assets/css/checkdata/checkdata.less";
</style>